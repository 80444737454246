import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "F:/KimchiSwap/Final Kwikswap 25-12-2020/Main Repos/Beadswap/org/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Beadswap can be used within other sites as an iframe. An iframe shows an exact version of the beadswap frontend site and can have custom prefilled settings.`}</p>
    <h1 {...{
      "id": "why-you-may-want-this",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#why-you-may-want-this",
        "aria-label": "why you may want this permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Why You May Want This`}</h1>
    <p>{`Integrating the Beadswap site directly into your web application can be useful for a variety of reasons.`}</p>
    <p>{`The interface allows users to buy, sell, send, or provide liquidity for ERC20 tokens. An iframe integration may be useful if your application provides services around these ERC20 tokens. `}{`(`}{`For example, users can buy DAI through a Beadswap iframe on your site, then allow users to lend that DAI on your site`}{`)`}{`.`}</p>
    <p>{`It can also be useful if your application requires users to acquire some token in order to use some service `}{`(`}{`For example, allow users to buy “REP” token so they can engage in prediction markets on the Augur Dapp`}{`)`}{`.`}</p>
    <h1 {...{
      "id": "iframe-vs-custom-ui",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#iframe-vs-custom-ui",
        "aria-label": "iframe vs custom ui permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`iframe vs. custom UI`}</h1>
    <p>{`One benefit of an iframe integration is that the your site will automatically keep up with any improvements/additions to the site. After the initital integration is setup no further work is needed to pull in updates as the exchange site is updated over time.`}</p>
    <h1 {...{
      "id": "live-example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#live-example",
        "aria-label": "live example permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Live Example`}</h1>
    <iframe src="https://app.beadswap.me/#/swap?exactField=input&exactAmount=10&inputCurrency=0x6b175474e89094c44da98b954eedeac495271d0f" height="660px" width="100%" style={{
      "border": "0",
      "margin": "0 auto",
      "marginBottom": ".5rem",
      "display": "block",
      "borderRadius": "10px",
      "maxWidth": "960px",
      "minWidth": "300px"
    }} />
    <p>{`An example of an Iframe integration can be found on the FOAM site `}<a parentName="p" {...{
        "href": "https://map.foam.space/#/at/?lng=-74.0045300&lat=40.6771800&zoom=5.00"
      }}>{`https://map.foam.space/`}</a></p>
    <p>{`To see the Iframe click the dropdown in the top right and click “get foam”.`}</p>
    <h1 {...{
      "id": "add-to-your-site",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#add-to-your-site",
        "aria-label": "add to your site permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add To Your Site`}</h1>
    <p>{`To include a Beadswap iframe within your site just add an iframe element within your website code and link to the Beadswap frontent.`}</p>
    <p>{`Linking to a ETH `}{`<`}{`-`}{`>`}{` DAI swap page would look something like this. To link to a token of your choice replace the address after “outputCurrency” with the token address of the token you want to link to.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`<iframe
  src="https://app.beadswap.me/#/swap?outputCurrency=0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359"
  height="660px"
  width="100%"
  style="
    border: 0;
    margin: 0 auto;
    display: block;
    border-radius: 10px;
    max-width: 600px;
    min-width: 300px;
  "
  id="myId"
/>
`}</code></pre>
    <p>{`You can customize the selected page, selected custom tokens and more using URL query parameters. See `}<Link to='/docs/v1/interface-integration/custom-interface-linking' mdxType="Link">{`Custom Linking`}</Link>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      